import {inject } from 'aurelia-framework';
import {Router} from 'aurelia-router';
import { EmployeeService } from '../../shared/services/employeeservice';
import {DialogService} from 'aurelia-dialog';
import { EditPerson } from '../editPerson/editperson';
import { ObserverLocator } from 'aurelia-binding';
import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../shared/services/loginservice";
import {JwtService} from "../../shared/services/jwtservice";

@inject(Router,EmployeeService,DialogService,Element,ObserverLocator,LoginService,JwtService)
export class DatatableComponent {
  
  favorites = [];
  employeedetails = [];
  searchTerm = '';
  data = [];
  sortby = 1;
  position = 'asc';
  //sorting based on column
  sortName = 'EmployeeID';
  sortDirection = 'ascending';
  globalColumn = '';
  //Buttons
  showPrevious = false;
  showNext = true;
  showNoData = false;
  showEmployeeData  = true;

  //Api Params
  search = ''; //search Global 
  searchTermObserver = null;
  globalSearchTerm = '';

     
  constructor(router,employeeservice,dialogService,element,observerLocator,loginservice,jwtService)
  {
    this.router = router;
    this.employeeservice = employeeservice;
    this.dialogService = dialogService;
    this.element = element;
    // Get an observer for the searchTerm property
    this.searchTermObserver = observerLocator.getObserver(this, 'searchTerm');
    // Subscribe to changes to the searchTerm property
    this.search;
    this.searchTermObserver.subscribe((newValue) => {
      //console.log('Search term changed:', newValue);
      this.search = newValue;
    });
    this.currentPage = 1;
    this.pagevalue = [];
    this.loginservice = loginservice;
      this.jwtService = jwtService;

  }

  //Fetch Employee Data

  async attached() {
      // if(this.jwtService.getToken() === undefined){
      //     this.loginservice.purgeAuth();
      //     this.router.navigateToRoute('login')
      // }
    this.fetchData(this.currentPage);
    const input = this.element.querySelector('#search-box');
    input.addEventListener('input', async () => {
        //console.log(this.searchTerm);
        if (this.searchTerm && this.searchTerm.length > 3) {
            const employeeData = await this.employeeservice.GetPagedEmployeeDetails(this.globalColumn, this.sortDirection, this.searchTerm, this.currentPage);
            this.employeedetails = employeeData;
            console.log('main');
            console.log(this.employeedetails.length);
            this.currentPageRecords = this.employeedetails.length;
            //this.showNext = false;
            if (this.employeedetails.length === 0) {
                // Third condition: Display "No data found" message
                console.log('3');
                this.fetchData(this.currentPage);
                console.log(this.employeedetails.length);
                this.currentPageRecords = this.employeedetails.length;
            } else {
                console.log('3 else');
                console.log(this.employeedetails.length);
                this.currentPageRecords = this.employeedetails.length;
                this.showNoDataMessage = false;
                this.showNext = false;
            }
        } else {
            const employeeData = await this.employeeservice.GetPagedEmployeeDetails(this.globalColumn, this.sortDirection, null, this.currentPage);
            this.employeedetails = employeeData;
            if (this.employeedetails.length === 0) {
                // Second condition: Display "No data found" message
                this.showNoDataMessage = true;
                this.showNext = false;
                this.showPrevious = false;
                console.log('2');
                this.fetchData(this.currentPage);
            } else {
                console.log('2 else');
                this.showNoDataMessage = false;
                this.showNext = true;
            }
        }
    });
    input.addEventListener('focus', () => {
      this.currentPage = 1; // Reset current page to 1 when input is focused
  });
    if (this.employeedetails.length === 0) {
        // First condition: Display "No data found" message
        console.log('1 throw error');
        this.showNoDataMessage = true;
        this.showNext = false;
    } else {
      console.log('1 throw error else');
        this.showNoDataMessage = false;
    }
    this.checkForNoRecordsMessage();
}

async fetchData(currentPage) {
    const employeeData = await this.employeeservice.GetPagedEmployeeDetails(this.globalColumn, this.sortDirection, this.searchTerm, currentPage);
    this.employeedetails = employeeData;
    if (this.employeedetails.length === 0) {
        // First condition: Display "No data found" message
        console.log('1');
        console.log(this.employeedetails.length);
        this.currentPageRecords = this.employeedetails.length;
        this.showNext = false;
        this.showPrevious = false;
        this.showNoDataMessage = true;
    } else {
      console.log('1 else');
      this.employeedetails = employeeData;
      console.log(this.employeedetails.length);
      this.currentPageRecords = this.employeedetails.length;
      if(this.employeedetails.length > 10){
        this.showNoDataMessage = false;
        this.showNext = false;
      }
      this.currentPageRecords = this.employeedetails.length;
        this.showNoDataMessage = false;
        this.showNext = true;
    }
    this.checkForNoRecordsMessage();
}

checkForNoRecordsMessage() {
    if (this.searchTerm && this.employeedetails.length !== 0 && this.employeedetails.every((employee) => employee.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) === -1)) {
        // Fourth condition: Display "No records to display" error message
        console.log('4 condi - No records to display');
        this.recordsError = 'No records to display';
    }
}


  //Next Pagination

  // nextPage() {
  //   console.log(this.employeedetails.length,'next page');
  //   if(this.pagevalue.length === 1) {
  //     this.showPrevious = true;  
  //     this.currentPage = this.pagevalue[0] + 1;
  //   } else if (this.pagevalue.length > 1) {
  //     let maxPageValue = Math.max(...this.pagevalue);
  //     this.currentPage = maxPageValue + 1;
  //   }
  //    else {
  //     this.currentPage++;     
  //   }
  //   this.showPrevious = true; 
  //   return this.employeeservice.GetPagedEmployeeDetails(this.globalColumn, this.sortDirection,this.searchTerm, this.currentPage)
  //     .then(data => {
  //       this.employeedetails = data;
  //       console.log(this.employeedetails);
  //       this.pagevalue.push(this.currentPage);
  //     });
  // }

  nextPage() {  
    this.currentPage++; 
    return this.employeeservice.GetPagedEmployeeDetails(this.globalColumn,this.sortDirection,this.searchTerm,this.currentPage)
    .then(data => {
    this.employeedetails = data;
    this.pagevalue.push(this.currentPage);
    if(this.currentPage > 1){
      this.showPrevious = true;
    }
    console.log(this.employeedetails);
  });
  }
  

  //Previous Pagination
    previousPage() {
      if (this.currentPage > 1) {     
      this.currentPage--; 
      return this.employeeservice.GetPagedEmployeeDetails(this.globalColumn,this.sortDirection,this.searchTerm,this.currentPage)
      .then(data => {
      this.employeedetails = data;
      this.pagevalue.pop();
      if(this.currentPage <= 1){
        this.showPrevious = false;
      }
      console.log(this.employeedetails);
    });      
      }
    }
  //On clicking Current Pagination
    goToPage(currentPage) {
      this.currentPage = currentPage;
      this.fetchData(currentPage);
    }

    //Sorting and Direction of the data

    sortData(column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      this.searchTerm = '';
      this.globalColumn = column;
      return this.employeeservice.GetPagedEmployeeDetails(this.globalColumn,this.sortDirection,this.searchTerm,this.currentPage)
      .then(data => {
      this.employeedetails = data;
      console.log(this.employeedetails);
    });
      }
  
    //PageSize
    getValue() {
      console.log('Selected value:', this.selectedSize);
      return this.pageSize = this.selectedSize;
    }

    //Dialog Open
    showDialog(user) {
      this.dialogService.open({viewModel: EditPerson, model: user});
    }

    
    cancel() {
      this.controller.cancel();
  }
}


import {inject,bindable } from 'aurelia-framework';
import {LoginService} from '../../shared/services/loginservice';
import {DashboardService} from '../../shared/services/dashboardservice';
import {Router} from 'aurelia-router';
import { EmployeeService } from '../../shared/services/employeeservice';
import {DialogService} from 'aurelia-dialog';
import Scrollbar from 'smooth-scrollbar';
import {JwtService} from '../../shared/services/jwtservice';
import {AttendanceService} from '../../shared/services/attendanceservice';
import { App } from '../../app';
import * as moment from 'moment';
import {SharedState} from "../../shared/state/sharedstate";


@inject(LoginService,Router,DashboardService,EmployeeService,DialogService,JwtService,AttendanceService,SharedState,App)
export class DashboardComponent {

  time='';
  comments='';
  attendanceDetails=[];
  inTime='';
  outTime='';
  record=[];
  message='';
  totalTime;
  firstInTime;
  @bindable login;
  taskViewList = [];
    holidayViewList = [];
    leaveViewList = [];
    announcementViewList = [];
    newTasks = 0;
    overTasks = 0;
    appreciations = 0;
    isPunchedIn = false;

  constructor(loginservice,router,dashboardservice,employeeservice,dialogService,JwtService,attendanceservice,sharedState,App)
  {
      this.loginservice = loginservice;
    this.router = router;
    this.dashboardservice = dashboardservice;
    this.employeeservice = employeeservice;
    this.dialogService = dialogService;
    this.jwtService = JwtService;
    this.attendanceService=attendanceservice;
    this.app=App;
      this.serverDate = ''; // Initialize serverDate
      this.formattedDate = '';
      this.formattedTime = '';
      this.timeZone = '';
    this.login=true;
      this.sharedState = sharedState;
  }

  bind(){
    this.isLoginPage=true;
    }

    /**
     * UpdatePropertyValue using this to show header and sidebar after login.
     * Added isLoading=false and scrollbar inside the api fetch data to work their functionality after loading the api data.
     * Moved scroll bar inside a if condition so it only works if the taskViewList have data.
     */
  attached(){

        // if(this.jwtService.getToken() === undefined){
        //     this.loginservice.purgeAuth();
        //     this.router.navigateToRoute('login')
        // }
        setTimeout(()=>{
        Scrollbar.init(document.querySelector('.smooth-scrollbar'));
      },2000);
      this.isLoading = true;
      this.empid = this.jwtService.getEmployeeId();
      // setInterval(() => {
      this.dashboardservice.GetDashboardDetails(this.empid)
          .then(data => {
            console.log();
              this.taskViewList = data.TaskViewList;
              this.holidayViewList = data.HolidayViewList;
              this.leaveViewList = data.LeaveViewList;
              this.announcementViewList = data.AnnouncementViewList;
              this.newTasks = data.NewTasks;
              this.OverDueTasks = data.OverDueTasks;
              this.appreciations = data.Appreciations;
              this.isLoading = false;
              this.serverDate = data.CurrentDate;
              console.log(this.serverDate);
              // Splitting date and time components
              const dateTimeParts = this.serverDate.split('T');
              this.formattedDate = this.formatDate(dateTimeParts[0]);
              this.formattedTime = this.formatTime(dateTimeParts[1]);
              //this.timeZone = this.formatTimeZone(dateTimeParts[2]);
          });
       // }, 1000); 
      if(this.taskViewList.length > 0){
          Scrollbar.init(document.querySelector('.smooth-scrollbar'));
      }
      this.app.updatePropertyValue(true);
      this.getAttendanceDetails();


    }


    /**
     * To update time for every second in frontend
     */
// Function to format the date (August 17, 2023)
    formatDate(dateStr) {
        const date = new Date(dateStr);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    // Function to format the time (18:34)
    formatTime(timeStr) {
        const timeWithoutSeconds = timeStr.split('.')[0]; // Removing milliseconds
        const timeComponents = timeWithoutSeconds.split(':');
        return `${timeComponents[0]}:${timeComponents[1]}`;
    }

    // // Function to format the time zone (UTC+05:30)
    // formatTimeZone(timeZoneStr) {
    //     const sign = timeZoneStr.charAt(0) === '+' ? 'UTC+' : 'UTC-';
    //     const time = timeZoneStr.substring(1);
    //     const hours = Math.floor(time / 60);
    //     const minutes = time % 60;
    //     return `${sign}${hours}:${minutes}`;
    // }

  /**
   * Function to get the attendance details of a employee.
   * Fetch the attendance details using empId and assigned the first array value to the variables
   * calculating the time for each array value by sending to calculateTotalTime function
   */
  async getAttendanceDetails(){
    await this.attendanceService.GetAttendanceDetails(this.empid)
        .then(data => {
            this.attendanceDetails= data;
            console.log(data);
            this.inTime = this.attendanceDetails.length !==0 ? this.attendanceDetails[0].InTime: null;
            this.outTime = this.attendanceDetails.length !==0 ? this.attendanceDetails[0].OutTime : null;
            this.attendanceId=this.attendanceDetails.length !==0 ? this.attendanceDetails[0].AttendanceID : null;
            this.totalTime = this.calculateTotalTime(this.attendanceDetails);
            this.firstInTime = this.attendanceDetails.length !==0 ? this.attendanceDetails[this.attendanceDetails.length-1].InTime: null;
        });
  };
    /**
     * This function is to calculate total time for each row in punchin / punch out details section
     * sending all the total time records to the this.totaltime and converting into minutes,seconds,hours in
     * attached().
     * @param attendanceData
     * @returns {string}
     */

  calculateTotalTime(attendanceData){
      let totalSeconds = 0;

      if(!attendanceData){
          return null;
      }
      for (let attendance of attendanceData) {
          if (attendance.InTime && attendance.OutTime) {
              let inTime = new Date(attendance.InTime);
              let outTime = new Date(attendance.OutTime);
              let diff = Math.abs(outTime - inTime);
              totalSeconds += Math.floor(diff / 1000);
          }
      }
      let hours = Math.floor(totalSeconds / 3600);
      let minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
      let seconds = totalSeconds - (hours * 3600) - (minutes * 60);
      let totalTime = `${hours} hours, ${minutes} minutes, and ${seconds} seconds`;
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }

  /**
  * Function for Punch In and Punch Out.
  *
  * calling getAttendanceDetails function to reload the punch In/punch Out section and showing successful message.
  */
  saveTime(){
    event.preventDefault();
    if(this.inTime !== null && this.outTime === null){
        let inAndOut={AttendanceID:this.attendanceId,OutTime:this.record.Time, OutTimeComment:this.record.Comments,EmployeeID:this.record.EmployeeID};
        const sendAttendanceDate = this.attendanceService.SaveAttendance(inAndOut);
        sendAttendanceDate.then(
            async () => await this.getAttendanceDetails(),
            this.message="Successfully punched out",
            this.record.Comments=''
        ).catch(e => console.error(e))
    }else {
        let inAndOut={InTime:this.record.Time,InTimeComment:this.record.Comments,EmployeeID:this.record.EmployeeID};
        const sendAttendanceDate = this.attendanceService.SaveAttendance(inAndOut);
        sendAttendanceDate.then(
            async () => await this.getAttendanceDetails(),
            this.message="Successfully punched in",
            this.record.Comments=''
        ).catch(e => console.error(e))
    }
  }

    /**
     * to convert data into day format .output show only day value
     * @param data
     * @returns {string}
     */

    dayFormat(data){
        const inputDate = data;
        const parsedDate = moment(inputDate, 'DD-MM-YYYY');
        const formattedDate = parsedDate.format('DD');
        return formattedDate;

    }

    /**
     * To convert data into month format .output show only month value
     * @param data
     * @returns {string}
     */


    monthFormat(data){
        const inputDate = data;
        const parsedDate = moment(inputDate, 'DD-MM-YYYY');
        const formattedDate = parsedDate.format('MMM');
        return formattedDate;
    }

    /**
     * To calculate days remaining for the next holiday, compare holiday date and today date
     * @param date
     * @returns {number}
     */

    timefornextholiday(date){
        const presentDate = new Date();
        const expiryDate = date;
        const presentdate = moment(presentDate, 'DD-MM-YYYY');
        const holidaydate = moment(expiryDate, 'DD-MM-YYYY');
        const daysremaing = holidaydate - presentdate;
        const timeDiffDays = Math.ceil(daysremaing / (24 * 60 * 60 * 1000));
        return timeDiffDays;

    }

    navigateToPage(support) {
      if (
            (navigator.userAgent.includes('Mac') && navigator.userAgent.includes('Safari')) ||
            (navigator.userAgent.includes('iPhone') && navigator.userAgent.includes('Safari'))
          ) {
            setTimeout(function () {
              window.location.reload();
            }, 500);
          } 
      this.router.navigateToRoute(support);
    }

    // redirectToOrganization(announcementId) {
    //     // Construct the URL for the organization tab and include the announcementId as a parameter
    //     const organizationUrl = `#/organization?announcementId=${announcementId}`;
    //
    //     // Navigate to the organization tab
    //     window.location.href = organizationUrl;
    // }

}


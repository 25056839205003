import {inject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {LoginService} from "../../shared/services/loginservice";
import {JwtService} from "../../shared/services/jwtservice";
import {Router} from 'aurelia-router';

 @inject(DialogController,LoginService,Router,JwtService)
 export class EditPerson {
     isDragging = false;
     dialog;
     static inject = [Element];
  message = '';

  constructor(controller,router,loginservice,jwtService) {
    this.controller = controller;
      this.router = router;
      this.loginservice = loginservice;
      this.jwtService = jwtService;
  }

  activate(message) {
    this.EmployeeID = message.EmployeeID;
    this.FirstName = message.FirstName;
    this.LastName = message.LastName;
    this.UserName = message.UserName;
    this.DepartmentName = message.DepartmentName;

  }
  attached(){
      // if(this.jwtService.getToken() === undefined){
      //     this.loginservice.purgeAuth();
      //     this.router.navigateToRoute('login')
      // }
  }

  async save() {
    const result = {
      EmployeeID:this.EmployeeID,
      FirstName: this.FirstName,
      LastName: this.LastName,
      UserName: this.UserName,
      DepartmentName:this.DepartmentName
       };
    console.log(result);
    this.controller.cancel();
    }

    cancel() {
      this.controller.cancel();
  }
     startDragging(event) {
         if (event.button === 0) {
             this.isDragging = true;
             this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
             this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

             window.addEventListener('mousemove', this.drag);
             window.addEventListener('mouseup', this.stopDragging);
         }
     }

     drag = (event) => {
         if (this.isDragging) {
             const x = event.clientX - this.offsetX;
             const y = event.clientY - this.offsetY;

             this.dialogElement.style.left = `${x}px`;
             this.dialogElement.style.top = `${y}px`;
         }
     }

     stopDragging = () => {
         this.isDragging = false;
         window.removeEventListener('mousemove', this.drag);
         window.removeEventListener('mouseup', this.stopDragging);
     }
 

  }
